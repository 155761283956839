import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.css']
})
export class PreloaderComponent implements OnInit {

   // Loader
   isLoading: boolean;
   constructor() { }
 
   ngOnInit() {
     this.isLoading = true;
 
     setTimeout(() => {
       this.isLoading = false;
     }, 600);
   }

}
